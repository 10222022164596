.Sign-section {
    margin-top: 68.8px;
}

.Sign-section .Sign-Up-card-section {
    border-radius: 10px;
    padding: 15px;
    border: 0;
    height: 100%;
}

.Sign-section .Sign-Up-card-section .Sign_Up_subheading {
    color: #A9A9A9;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;

}

.Sign-section .Sign-Up-card-section .form-label {
    color: #555555;
    font-weight: 400;
}

.Sign-section .Sign-Up-card-section .Sign_btn_submit {
    border-radius: 12px;
    background: #006BB8;
    color: #fff;
    padding: 10px 40px;
    border: 0;
}

.Sign-section .Sign-Up-card-section .Sign_btn_submit :hover {
    border-radius: 12px;
    background: #006BB8;
    color: #fff;
    padding: 10px 40px;
    border: 0;
}

.Sign-section .Sign-Up-card-section .form-check-input {
    border-radius: 3px;
    border: 1px solid #006BB8;

}

.Sign-section .Sign-Up-card-section .form-check-label {
    color: #555555;

}

.Sign-Up-card-section-img {
    border-radius: 10px;
    padding: 75px 15px;
    border: 0;
    height: 100%;
}

.Sign-section .nav-pills-bg {
    background: #fff;
    border-radius: 100px;
    padding: 5px;
    width: fit-content;
    justify-content: space-around;
    margin: auto;
}

.Sign-section .nav-pills .nav-link.active {
    background-color: #7FCAFF;
    border-radius: 100px;
    color: #000;
    font-weight: 700;
}

.Sign-section .nav-pills .nav-link {
    padding: 10px 40px;
    color: #5F5F5F;
    font-weight: 400;
}

.forget_password {
    color: #7D7D7D;
    text-decoration: none;
    font-weight: 400;
}


.Sign-section-Password {
    margin: 100px 0;
}

.toggle-password {
    position: absolute;
    margin-left: 458px;
    margin-top: -33px;
}
.toggle-password-confirmBox {
    position: absolute;
    margin-left: 458px;
    margin-top: -33px;
}