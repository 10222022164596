@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

body {
    font-family: "Lato", sans-serif;
    background: #e5f4ff;
}
.header_section {
    background-color: #006bb8f2;
    padding: 0px 0;

    .logo_area {
        img {
            height: 40px;
        }
    }
}
